import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const w07GL01Schnuppern = () => (
  <Layout>
    <SEO title="w07GL01Schnuppern" />

    <h1 id="ueberschrift">Gemeinsam Lesen</h1>
    <h2>Schnuppertreff für Interessierte &emsp;  ©&nbsp;1993</h2>

      <p>
        Kontakt:&ensp;
        <a href="mailto:iarna@bastet-project.de?subject=Anfrage zu w07GL01Schnuppern&amp;body=Bitte senden Sie so viele Daten wie möglich (z.B.: gewünschter Ort, Tag, Anzahl der Teilnehmer, Altersgruppe, etc.)." title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>iarna@bastet-project.de</a>
      </p>

      <p>
        Bei dem Versuch einen Text laut (vor-)zu lesen scheint sich manchmal die Zunge verknoten zu wollen.
        Zum Teil weigern sich die Wörter den Mund zu verlassen und dann wieder wollen alle auf einmal ans Tageslicht.
        Und dann gibt es noch das Phänomen, dass die Buchstaben über die Seiten tanzen und sich nur schwer entziffern lassen.
        Dabei ist die Kunst des lauten Lesens in mehrerlei Hinsicht hilfreich.
        Es kann sogar das freie Sprechen für z.&nbsp;B. Referate
        und Präsentationen erleichtern.
      </p>
      <p>
        Meist ist es einfacher etwas zu verstehen, das erlebt oder zumindest angeschaut werden kann.
        Sie können die Gelegenheit nutzen und einen ersten Eindruck gewinnen,
        indem Sie sich über die angegebene Kontaktmöglichkeit melden. Gemeinsam ermitteln wir dann den
        für Sie günstigsten Termin, damit Sie in das Themengebiet "hineinschnuppern" können.
      </p>
      
      <p style={{fontStyle: "italic", textDecoration: "underline"}}>
        Informationskurs (+Mitmachkurs)
      </p>
      <p>
        für alle Altersgruppen<br/>
        Materialbedarf: keiner
      </p>
      <p>
        + keine besonderen Voraussetzungen nötig + <br/>
      </p>

    <Link to="/fdml_1a_aktuelles-klartext/" title="Detaillierte Informationen zum Bereich Veranstaltungen &amp; mehr" >Aktuelle Termine</Link>
      <p></p>
  </Layout>
              )

              export default w07GL01Schnuppern
